import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import ContentPreview from '../components/ContentPreview'
import Layout from '../components/App/Layout'

class BirdsIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBirdPost.nodes')

    return (
      <Layout location="blog">
        <div className="bread-cumbs-area bread-cumbs-bg-animals">
          <div className="diplay-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <h1>{`Les Oiseaux du ZOO TALI N'NA`}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="birds" className="our-blog main-blog bg-none">
          <div className="container">
            <div className="row">
              <ContentPreview posts={posts} root={'birds'} showButton={false} />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BirdsIndex

export const pageQuery = graphql`
  query BirdsIndexQuery {
    allContentfulBirdPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        qty
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
          url
        }
        description {
          raw
        }
      }
    }
  }
`
